<style lang="scss" scoped>
.line {
	margin: 0.3rem 0.3rem;
	height: 1px;
	background: $line_color;
}
::v-deep .van-cell {
	padding-left: 0.3rem;
	padding-right: 0.3rem;
}
::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
}
::v-deep .cell_value {
	color: $font_color_val;
	font-size: 0.24rem;
}
::v-deep .van-field__body input {
	color: $font_color_val;
}
::v-deep .no_link .van-icon-arrow {
	color: #fff;
}
.btns {
	margin-top: 0.6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	.btn {
		width: 3rem;
		height: 0.9rem;
		font-size: 0.3rem;
		line-height: 0.9rem;
		text-align: center;
		border-radius: 0.08rem;
	}
	.btn_cancel {
		border: 1px solid $color_main;
		color: $color_main;
		background-color: #fff;
		margin-right: 0.4rem;
	}
	.btn_sure {
		border: 1px solid $color_main;
		color: #fff;
		background-color: $color_main;
	}
}
.warn {
	font-size: 0.18rem;
	color: $danger;
	margin-left: 0.1rem;
}
</style>

<template>
	<div>
		<top-nav @back="back">被保人信息</top-nav>
		<van-form ref="form" input-align="right" error-message-align="right">
			<van-cell-group :border="false">
				<van-field label="与投保人关系" :value="userInfo.insureRelation" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" placeholder="请选择与投保人关系" :rules="rules.insureRelation" @click="relationPop = true" />
				<van-field label="姓名" v-model="userInfo.userName" :formatter="nameFormatter" class="no_link" label-class="cell_title" value-class="cell_value" :border="false" is-link :rules="rules.userName" placeholder="请填写被保人姓名" />
				<van-field label="证件类型" :value="userInfo.cardType" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" placeholder="请选择证件类型" @click="cardTypePop = true" />
				<van-field label="证件号码" v-model="userInfo.cardNum" :formatter="idCardNoFormatter" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" @input="cardNumChange" :rules="rules.cardNum" placeholder="请填写被保人证件号码" />
				<van-field label="出生日期" :value="userInfo.birthday" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" :rules="rules.birthday" placeholder="请选择出生日期" @click="birthdayPop = true" />
				<van-field label="性别" :value="userInfo.sex" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" :rules="rules.sex" placeholder="请选择性别" @click="sexPop = true" />
				<van-field label="手机号码" v-model="userInfo.phone" :formatter="mobileFormatter" class="no_link" label-class="cell_title" value-class="cell_value" :border="false" is-link placeholder="请填写被保人手机号码" :rules="rules.phone" />

				<van-field label="职业类别" :value="userInfo.job" label-class="cell_title" value-class="cell_value" :border="false" is-link readonly clickable name="picker" placeholder="请选择职业类别" :rules="rules.job" @click="jobPop = true" />

				<div class="line"></div>
			</van-cell-group>
		</van-form>

		<div class="btns">
			<div class="btn btn_cancel" @click="cancel">取消</div>
			<div class="btn btn_sure" @click="sure">确定</div>
		</div>

		<!-- 弹出层 -->
		<!-- 与投保人关系 -->
		<van-popup v-model="relationPop" position="bottom" :style="{ height: '30vh' }">
			<van-picker title="与投保人关系" show-toolbar value-key="label" :visible-item-count="3" :columns="relationList" @confirm="relationCheck" @cancel="relationPop = false" />
		</van-popup>
		<!-- 证件类型 -->
		<van-popup v-model="cardTypePop" position="bottom" :style="{ height: '30vh' }">
			<van-picker title="证件类型" show-toolbar value-key="label" :visible-item-count="3" :columns="cardTypeList" @confirm="cardTypeCheck" @cancel="cardTypePop = false" />
		</van-popup>
		<!-- 出生日期 -->
		<van-popup v-model="birthdayPop" position="bottom" :style="{ height: '30vh' }">
			<van-datetime-picker v-model="birthday" type="date" title="选择年月日" @cancel="birthdayPop = false" @confirm="birthdayCheck" visible-item-count="3" :min-date="birthdayMinDate" />
		</van-popup>
		<!-- 性别 -->
		<van-popup v-model="sexPop" position="bottom" :style="{ height: '30vh' }">
			<van-picker title="性别" show-toolbar value-key="label" :visible-item-count="3" :columns="sexList" @confirm="sexCheck" @cancel="sexPop = false" />
		</van-popup>

		<!-- 职业类别 -->
		<van-popup v-model="jobPop" position="bottom">
			<van-cascader v-model="jobType" :options="jobPopList" :field-names="jobSourceMap" @finish="jobCheck" @close="jobPop = false">
				<template #title>
					<div>
						<span>职业类别</span>
						<span class="warn">(请如实选择职业，否则存在拒赔风险)</span>
					</div>
				</template>
			</van-cascader>
		</van-popup>
		<page-footer p></page-footer>
	</div>
</template>

<script>
import { Cell, CellGroup, Popup, Picker, Calendar, Field, DatetimePicker, Checkbox, Form, Cascader } from 'vant';
import { cardType, sexs, relationList } from '@/config/fixedParams';
import regular from '@/assets/js/regular';
import Mtils from 'mtils';
import { http_getJobList } from '@/request/sundry';
import bus from '@/components/bus';

export default {
	name: 'addUser',
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Popup.name]: Popup,
		[Picker.name]: Picker,
		[Calendar.name]: Calendar,
		[Field.name]: Field,
		[DatetimePicker.name]: DatetimePicker,
		[Checkbox.name]: Checkbox,
		[Form.name]: Form,
		[Cascader.name]: Cascader,
	},
	data() {
		return {
			fromRoute: '',
			// 投保人信息
			userInfo: {
				userType: '',
				insureRelation: '',
				userName: '',
				cardType: '',
				cardNum: '',
				birthday: '',
				sex: '',
				phone: '',
				email: '',
				buyNum: '1',
				id: undefined,
				job: '',
				jobDict: null, // 选择的职业类别对象
			},
			rules: {
				insureRelation: [{ required: true }],
				job: [{ required: true, message: '' }],
				userName: [
					{ required: true, message: '' },
					{ pattern: regular.name1, message: '只能输入中文或英文以及·' },
					{ pattern: regular.name2, message: '至少2个中文或4个英文字母' },
					{ validator: this.nameCheck, message: '·不能出现在首尾' },
				],
				cardNum: [
					{ required: true, message: '' },
					{ validator: this.cardNumCheck, message: '证件号码有误' },
				],
				birthday: [{ required: true }],
				sex: [{ required: true }],
				phone: [{ validator: this.phoneCheck, message: '请填写正确的手机号码' }],
			},
			relationPop: false,
			cardTypePop: false,
			birthdayPop: false,
			jobPop: false,
			relationList: [],
			cardTypeList: [],
			jobPopList: [],
			birthdayMinDate: new Date('1900/01/01'),
			birthday: new Date('1990/01/01'),
			sexPop: false,
			sexList: [],
			planId: '', // 计划id
			jobType: '',
			jobSourceMap: {
				text: 'profName',
				value: 'profCode',
				children: 'children',
			},
		};
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.fromRoute = from.name;
		});
	},
	created() {
		let query = this.$route.query;
		this.planId = query.plainId;
		this.getJobDict();
		this.editInit();
		this.initFixedParams();
	},
	methods: {
		// 获取职业类别
		async getJobDict() {
			let res = await http_getJobList(this.$route.query.companyId);
			this.jobPopList = res;
		},

		// 过滤职业，不保五类职业，已经不用了
		jobFilter(list, r = []) {
			list.forEach(item => {
				if (item.codeData !== '5') {
					r.push(item);
					if (item.children && item.children.length) {
						let nList = item.children;
						item.children = [];
						this.jobFilter(nList, item.children);
					}
				}
			});
		},

		// 编辑信息初始化
		editInit() {
			let query = this.$route.query;
			if (query.type === 'edit' && query.hasOwnProperty('userInfo')) {
				Object.assign(this.userInfo, JSON.parse(query.userInfo));
			}
		},

		// 投保人名称检测
		nameCheck(val) {
			if (val[0] === '·' || val[val.length - 1] === '·') {
				return false;
			}
			return true;
		},

		// 投保人姓名格式化
		nameFormatter(v) {
			return v.toLocaleUpperCase().replace(/ /g, '');
		},

		// 身份证号码格式化
		idCardNoFormatter(v) {
			return String(v).toLocaleUpperCase().replace(/ /g, '');
		},

		// 手机号码格式化
		mobileFormatter(v) {
			return String(v).replace(/ /g, '');
		},

		// 初始化固定参数
		initFixedParams() {
			this.cardTypeList = cardType;
			if (!this.userInfo.cardType) {
				this.userInfo.cardType = cardType[0].label;
			}
			this.sexList = sexs;
			this.relationList = relationList;
		},

		jobCheck({ selectedOptions }) {
			let dict = selectedOptions[selectedOptions.length - 1];
			this.userInfo.job = dict.profName;
			this.userInfo.jobDict = dict;
			this.jobPop = false;
		},

		relationCheck(val) {
			this.userInfo.insureRelation = val.label;
			this.relationPop = false;
		},

		cardTypeCheck(val) {
			this.userInfo.cardType = val.label;
			this.cardTypePop = false;
		},

		birthdayCheck(val) {
			this.userInfo.birthday = this.$base.dateFormater(val);
			this.birthdayPop = false;
		},

		sexCheck(val) {
			this.userInfo.sex = val.label;
			this.sexPop = false;
		},

		// 证件号码输入
		cardNumChange(val) {
			if (val && this.userInfo.cardType === '身份证') {
				// 处理大小写问题
				this.userInfo.cardNum = String(val).toLocaleUpperCase();
			}
			if (this.userInfo.cardType === '身份证' && Mtils.validation.isIdCard(this.userInfo.cardNum)) {
				// 计算
				let info = this.$base.getInfoFromIdNumber(val);
				this.userInfo.birthday = info.birthday;
				this.userInfo.sex = info.sex;
			}
		},

		cancel() {
			this.back();
		},

		phoneCheck(val) {
			if (!val) {
				return true;
			}
			return regular.phone.test(val);
		},

		// 证件类型校验方法
		cardNumCheck(val) {
			if (this.userInfo.cardType === '身份证') {
				return Mtils.validation.isIdCard(val);
			}
			if (this.userInfo.cardType === '护照') {
				return regular.passport.test(val) && !regular.repeatReg7.test(val) && !regular.sequentialReg7.test(val);
			}
			if (this.userInfo.cardType === '军官证') {
				return regular.army.test(val);
			}
			if (this.userInfo.cardType === '港澳回乡证') {
				return regular.hkCard.test(val);
			}
			if (this.userInfo.cardType === '台胞证') {
				return regular.twCard.test(val);
			}
			return true;
		},

		sure() {
			this.$refs.form.validate().then(() => {
				bus.$emit('addUser', this.userInfo);
				this.back();
			});
		},

		back() {
			this.$router.go(-1);
		},
	},
};
</script>
